import { useEffect, useState } from 'react';

const LivePreview = ({ config }) => {
  const [widgetIsLoaded, setWidgetIsLoaded] = useState(false);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = process.env.REACT_APP_EMBED_URL;
    document.body.appendChild(scriptElement);

    const onWidgetLoaded = () => {
      setWidgetIsLoaded(true);
    };
    window.addEventListener('GeckoEngagementWidget:loaded', onWidgetLoaded);

    return () => {
      window.removeEventListener(
        'GeckoEngagementWidget:loaded',
        onWidgetLoaded
      );
      document.body.removeChild(scriptElement);
      const engagementWidget = Array.from(
        document.getElementsByClassName('GeckoEngagementWidget')
      );
      if (engagementWidget.length) {
        document.body.removeChild(engagementWidget[0].parentElement);
      }
    };
  }, []);

  useEffect(() => {
    if (widgetIsLoaded === true) {
      window.GeckoEngagementWidget.setConfig(config);
    }
  }, [widgetIsLoaded, config]);

  return null;
};

export default LivePreview;
