import React, { cloneElement } from 'react';
import ReactTooltip from 'react-tooltip';
import './index.scss';

const Tooltip = ({
  children,
  message,
  place = 'bottom',
  ...props
}) => {
  const childrenWithProps = cloneElement(children, { 'data-tip': message });
  return (
    <>
      {childrenWithProps}
      <ReactTooltip
        className='GeckoBuilder-Tooltip'
        multiline
        effect="solid"
        place={place}
        {...props}
      />
    </>
  );
};

export default Tooltip;
