import { Link } from '@reach/router'
import { GeckoIcon } from '@geckolabs/gecko-react-ui';

const LogoutNav = () => {
  return (
    <Link className="GeckoBuilder-Header-Logout" to="/logout">
      <GeckoIcon icon="sign-out" />
      Logout
    </Link>
  );
};

export default LogoutNav;
