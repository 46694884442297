import { GeckoIcon } from '@geckolabs/gecko-react-ui';
import { Button } from '..';
import './index.scss';

const progressIconNames = {
  incomplete: 'ellipsis-h',
  complete: 'check',
};

const StepCard = ({
  name,
  open,
  step,
  stepState,
  onClickHeader,
  footerButtons,
  children,
}) => {
  const progressIcon = progressIconNames[stepState];

  return (
    <div className="GeckoBuilder-Card">
      <div className="GeckoBuilder-Card-Header" onClick={onClickHeader}>
        <div className="step-number">{step}</div>
        <div className="step-name">{name}</div>
        <div className={`step-progress ${stepState}`}>
          <GeckoIcon icon={progressIcon} />
        </div>
      </div>
      {open && (
        <>
          <div className="GeckoBuilder-Card-Body">{children}</div>
          <div className="GeckoBuilder-Card-Footer">
            {footerButtons.map((footerButton, index) => <Button {...footerButton} key={index} />)}
          </div>
        </>
      )}
    </div>
  );
};

export default StepCard;
