import { useContext } from 'react';
import {
  GECKO_CONSTS,
  GeckoIcon,
  GeckoFields,
  GeckoAlert,
} from '@geckolabs/gecko-react-ui';
import { AppContext, BuilderContext, TourContext } from '../../../../context';
import { ButtonRadio, Tooltip } from '../../../../components';
import { TourCard } from '..';
import { defaultComponentFields } from '../..';

const FieldLink = ({ type, error, value, ...rest }) => {
  const linkDetails = {
    Form: {
      href: 'https://app.geckoform.com/#/forms',
      tooltip: 'Go to Forms > Select Form > Share > Copy Webform Link',
      linkMessage: 'Get your Webform Link',
    },
    Event: {
      href: 'https://app.geckoform.com/#/events/list?filter=active',
      tooltip: 'Go to Events > Share > Pick a Booking Form > Copy Webform Link',
      linkMessage: 'Get your Webform Link',
    },
    Chat: {
      href: 'https://app.geckochat.io/#/settings/channels',
      tooltip: 'Go to Channels > Pick Chat Channel > Copy Chat account_id',
      linkMessage: 'Get your Chat ID',
    },
    Whatsapp: {
      href: 'https://academy.geckoengage.com/en/articles/6962551-channels#h_4870dc166e',
      tooltip:
        'Click to see our Academy article on how to direct to your WhatsApp',
      linkMessage: 'See how to link WhatsApp',
    },
  };
  const { href, tooltip, linkMessage } = linkDetails[type];
  return (
    <>
      <input value={value} {...rest} />
      {error && value && <span className="FieldLink-error">{error}</span>}
      <Tooltip message={tooltip}>
        <a className="FieldLink" href={href} target="_blank" rel="noreferrer">
          <GeckoIcon icon="fa-link" />
          &nbsp;{linkMessage}
        </a>
      </Tooltip>
    </>
  );
};

const TypeField = (props) => {
  const { componentIndex, componentFields } = useContext(BuilderContext);
  const { tourActive, tourStep, advanceTour } = useContext(TourContext);
  const { mobileSize } = useContext(AppContext);

  const options = [
    { name: 'Gecko Form', value: 'form', icon: 'far fa-list' },
    { name: 'Gecko Event', value: 'event', icon: 'far fa-calendar-alt' },
    {
      name: 'Gecko Chat',
      value: 'chat',
      icon: 'far fa-comments',
    },
    { name: 'Web Link', value: 'url', icon: 'far fa-globe' },
    { name: 'WhatsApp', value: 'whatsapp', icon: 'fab fa-whatsapp' },
    {
      name: '3rd Party Widget',
      value: 'thirdPartyWidget',
      icon: 'far fa-external-link-alt',
    },
    {
      name: 'Video',
      value: 'embeddedVideo',
      icon: 'far fa-video',
    },
  ];
  return (
    <>
      {!mobileSize && (
        <ButtonRadio
          {...props}
          id={componentIndex}
          value={componentFields.type}
          options={options}
          large
        />
      )}
      {mobileSize && (
        <div className="MobileSelect">
          <select {...props}>
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.name}
              </option>
            ))}
          </select>
          <GeckoIcon icon="fa-chevron-down" />
        </div>
      )}
      {tourActive && tourStep === 2 && (
        <TourCard
          className="ComponentTypeTour"
          title="Choose a Component"
          content="Start by choosing a Component to add to your widget."
          btn={{
            name: 'Next',
            buttonClass: 'primary',
            onClick: advanceTour,
          }}
          pointerPosition="bottom"
        />
      )}
    </>
  );
};

const LabelField = (props) => {
  const { tourActive, tourStep, advanceTour } = useContext(TourContext);
  const { mobileSize } = useContext(AppContext);
  return (
    <>
      <input placeholder="Description of your component" {...props} />
      {tourActive && tourStep === 3 && (
        <TourCard
          className="ComponentDetailsTour"
          title="Modify your Component"
          content="Pick a label for your component and fine-tune the details."
          btn={{
            name: 'Next',
            buttonClass: 'primary',
            onClick: advanceTour,
          }}
          pointerPosition={mobileSize ? 'bottom' : 'left'}
        />
      )}
    </>
  );
};

const BuilderComponent = () => {
  const {
    components,
    componentFields,
    componentErrors,
    setFieldValue,
    componentIndex,
    active,
    activeComponent,
    setActiveComponent,
    onDelete,
  } = useContext(BuilderContext);
  const { type, vendor } = componentFields;

  const handleChangeType = (newType) => {
    if (componentFields.type === newType) return;

    const newComponentFields = defaultComponentFields[newType];
    const updatedComponents = [...components];
    updatedComponents.splice(componentIndex, 1, newComponentFields);

    setFieldValue('widgetComponents', updatedComponents);
  };

  const setBuilderFieldValue = (key, value) => {
    if (key === 'type') {
      return handleChangeType(value);
    }
    const updatedComponentFields = { ...componentFields, [key]: value };
    const updatedComponents = [...components];
    updatedComponents.splice(componentIndex, 1, updatedComponentFields);
    setFieldValue('widgetComponents', updatedComponents);
  };

  const handleHeaderClick = () => {
    if (active) {
      return setActiveComponent(null);
    }

    setActiveComponent(componentIndex);
  };

  const handleMoveComponent = (fromIndex, toIndex) => {
    const updatedComponents = [...components];
    const [elementToMove] = updatedComponents.splice(fromIndex, 1);
    updatedComponents.splice(toIndex, 0, elementToMove);
    setFieldValue('widgetComponents', updatedComponents);
    if (activeComponent !== fromIndex && activeComponent !== toIndex) return;
    if (activeComponent === fromIndex) return setActiveComponent(toIndex);
    setActiveComponent(fromIndex);
  };

  const typeField = {
    key: 'type',
    label: 'Choose a Widget Component',
    width: '100%',
    type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
    component: <TypeField />,
  };

  const getInfoField = () => {
    const infoText = {
      event: (
        <span>
          Share upcoming on-campus, virtual, or hybrid events with students and
          let them register then and there. Gecko account required. Learn about
          unlocking Events{' '}
          <a
            href="https://geckoengage.com/our-technology/on-campus-events/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </span>
      ),
      form: (
        <span>
          Capture form responses from students and sync them to your CRM. Sit
          back and enjoy easy to set up workflows that automate your comms.
          Gecko account required. Learn more about unlocking Forms{' '}
          <a
            href="https://geckoengage.com/our-technology/college-fairs-off-campus-recruitment-events/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </span>
      ),
      chat: (
        <span>
          Let your students find answers quicker by starting a 1-to-1 live chat
          with your team or your very own Chatbot. Gecko account required. Learn
          about unlocking Chat{' '}
          <a
            href="https://geckoengage.com/our-technology/live-chat/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </span>
      ),
      url: (
        <span>
          Draw attention to strategic web pages which students can access with a
          single click. Opens as a new tab or as a modal.
        </span>
      ),
      thirdPartyWidget: (
        <span>
          A great option if you’re making use of a third-party communication
          tool you’d like students to access from your widget. Examples include
          Ocelot, Unibuddy, Intercom, and many more.
        </span>
      ),
      embeddedVideo: (
        <span>
          Embed videos for your students that inform, educate and entertain.
          Examples include YouTube, Vimeo, Wistia, and many more.
        </span>
      ),
      whatsapp: (
        <span>
          Add a link to your WhatsApp account so students can quickly and easily
          send you messages.
        </span>
      ),
    };
    return {
      key: 'infoField',
      type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
      width: '100%',
      component: (
        <GeckoAlert type={GECKO_CONSTS.ALERT_TYPE_INFO}>
          <GeckoIcon
            style={GECKO_CONSTS.ICON_STYLE_SOLID}
            icon="fa-question-circle"
          />
          &nbsp;&nbsp;
          {infoText[type]}
        </GeckoAlert>
      ),
    };
  };

  const labelField = {
    key: 'label',
    label: 'Widget Component Label',
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
    width: '100%',
    component: <LabelField />,
  };

  const urlFields = [
    {
      key: 'url',
      label: 'Enter the URL',
      required: true,
      type: GECKO_CONSTS.FIELD_TYPE_TEXT,
      width: '100%',
      placeholder: 'https://www.google.com',
    },
    {
      key: 'openInModal',
      label: `Open URL in Modal`,
      type: GECKO_CONSTS.FIELD_TYPE_TOGGLE,
      width: '100%',
    },
  ];

  const getShareField = (type) => ({
    key: 'url',
    label: `Enter your Webform Link`,
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
    width: '100%',
    component: <FieldLink type={type} error={componentErrors?.url} />,
  });

  const chatIdField = {
    key: 'chatId',
    label: 'Gecko Chat Widget ID',
    width: '100%',
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
    component: <FieldLink type="Chat" error={componentErrors?.chatId} />,
  };

  const whatsappURLField = {
    key: 'url',
    label: 'Enter your WhatsApp API URL',
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
    width: '100%',
    component: (
      <FieldLink
        type={'Whatsapp'}
        error={componentErrors?.url}
        placeholder={
          'https://api.whatsapp.com/send?phone=(your-phone-number-in-+44-format)&text=Your%20text%20here'
        }
      />
    ),
  };

  const getThirdPartyVendorFieldOptions = () => {
    const defaultOptions = [
      {
        value: 'intercom',
        label: 'Intercom',
      },
      {
        value: 'ocelot',
        label: 'Ocelot',
      },
      {
        value: 'unibuddy',
        label: 'Unibuddy',
      },
      {
        value: 'salesforce',
        label: 'Salesforce Einstein',
      },
      {
        value: 'ambassador',
        label: 'Ambassador Platform',
      },
    ];

    return defaultOptions.filter(({ value }) => {
      if (vendor === value) return true;
      return !components.some(({ vendor }) => vendor === value);
    });
  };

  const thirdPartyVendorField = {
    key: 'vendor',
    label: 'Third Party Vendor',
    width: '100%',
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_SELECT,
    options: getThirdPartyVendorFieldOptions(),
  };

  const getThirdPartyEmbedField = (vendor) => {
    const defaultEmbedField = {
      key: 'embedCode',
      label: 'Widget Embed Code',
      width: '100%',
      required: true,
      type: GECKO_CONSTS.FIELD_TYPE_TEXT,
    };

    const thirdPartyEmbedField = {
      intercom: {
        ...defaultEmbedField,
        label: 'Intercom Messenger App ID',
        placeholder: 'lcgtm5pf',
      },
      ocelot: {
        ...defaultEmbedField,
        label: 'Ocelot Bot ID',
        placeholder: 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa',
      },
      unibuddy: {
        ...defaultEmbedField,
        label: 'UniBuddy Widget Source Code',
        placeholder: 'https://unibuddy.co/embed/school-name/colour/FFFFFF',
      },
      salesforce: {
        ...defaultEmbedField,
        type: GECKO_CONSTS.FIELD_TYPE_TEXTAREA,
        label: 'Salesforce Einstein Source Code',
      },
      ambassador: {
        ...defaultEmbedField,
        label: 'Ambassador Platform Widget Source Code',
        placeholder: 'https://tappage.theaccessplatform.com/group/...',
      },
    };

    return thirdPartyEmbedField[vendor];
  };

  const videoEmbedField = {
    key: 'embedCode',
    label: 'Video Embed Code',
    width: '100%',
    required: true,
    type: GECKO_CONSTS.FIELD_TYPE_TEXTAREA,
  };

  const getIconField = () => {
    let options;

    switch (type) {
      case 'form':
        options = [
          { value: 'far fa-list', icon: 'far fa-list' },
          { value: 'far fa-list-ul', icon: 'far fa-list-ul' },
          { value: 'far fa-list-ol', icon: 'far fa-list-ol' },
          { value: 'far fa-clipboard-list', icon: 'far fa-clipboard-list' },
          { value: 'far fa-list-alt', icon: 'far fa-list-alt' },
        ];
        break;
      case 'event':
        options = [
          { value: 'far fa-calendar-alt', icon: 'far fa-calendar-alt' },
          { value: 'far fa-calendar', icon: 'far fa-calendar' },
          { value: 'far fa-calendar-star', icon: 'far fa-calendar-star' },
          { value: 'far fa-calendar-check', icon: 'far fa-calendar-check' },
          { value: 'far fa-calendar-plus', icon: 'far fa-calendar-plus' },
        ];
        break;
      case 'chat':
        options = [
          { value: 'far fa-comments', icon: 'far fa-comments' },
          { value: 'far fa-comment-dots', icon: 'far fa-comment-dots' },
          { value: 'far fa-comment-lines', icon: 'far fa-comment-lines' },
          { value: 'far fa-comment-smile', icon: 'far fa-comment-smile' },
          {
            value: 'far fa-comment-alt-smile',
            icon: 'far fa-comment-alt-smile',
          },
        ];
        break;
      case 'thirdPartyWidget':
        options = [
          { value: 'far fa-comment-alt', icon: 'far fa-comment-alt' },
          { value: 'far fa-comment-alt-dots', icon: 'far fa-comment-alt-dots' },
          {
            value: 'far fa-comment-alt-lines',
            icon: 'far fa-comment-alt-lines',
          },
          { value: 'far fa-comment-smile', icon: 'far fa-comment-smile' },
          { value: 'far fa-comment-dots', icon: 'far fa-comment-dots' },
        ];
        break;
      case 'embeddedVideo':
        options = [
          { value: 'far fa-video', icon: 'far fa-video' },
          { value: 'far fa-video-plus', icon: 'far fa-video-plus' },
          { value: 'far fa-film', icon: 'far fa-film' },
          { value: 'far fa-play', icon: 'far fa-play' },
        ];
        break;
      case 'url':
        options = [
          { value: 'far fa-globe', icon: 'far fa-globe' },
          { value: 'far fa-browser', icon: 'far fa-browser' },
          { value: 'far fa-wifi', icon: 'far fa-wifi' },
          { value: 'far fa-link', icon: 'far fa-link' },
          {
            value: 'far fa-external-link-alt',
            icon: 'far fa-external-link-alt',
          },
        ];
        break;
      default:
        options = [];
    }
    return {
      key: 'icon',
      label: 'Widget Icon',
      width: '100%',
      type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
      required: true,
      component: (
        <ButtonRadio
          id="component-icon"
          value={componentFields.icon}
          options={options}
        />
      ),
    };
  };

  const getFields = (type) => {
    const fields = [typeField];
    const additionalFields = [];
    switch (type) {
      case 'form':
        additionalFields.push(getShareField('Form'));
        break;
      case 'event':
        additionalFields.push(getShareField('Event'));
        break;
      case 'chat':
        additionalFields.push(chatIdField);
        break;
      case 'thirdPartyWidget': {
        additionalFields.push(thirdPartyVendorField);
        if (vendor) {
          additionalFields.push(getThirdPartyEmbedField(vendor));
        }
        break;
      }
      case 'embeddedVideo': {
        additionalFields.push(videoEmbedField);
        break;
      }
      case 'url':
        additionalFields.push(...urlFields);
        break;
      case 'whatsapp':
        additionalFields.push(whatsappURLField);
        break;
      default:
        break;
    }

    if (type) {
      fields.push(
        ...[
          getInfoField(),
          labelField,
          ...additionalFields,
          type !== 'whatsapp' && getIconField(),
        ]
      );
    }

    return fields;
  };

  const onDeleteClick = (event) => {
    event.stopPropagation();
    return onDelete();
  };

  const showUpArrow = componentIndex !== 0;
  const showDownArrow = componentIndex !== components.length - 1;

  return (
    <div className="builderComponent">
      <div
        className={`builderComponent-header ${active ? 'active' : ''}`}
        onClick={handleHeaderClick}
      >
        <div className="builderComponent-header-details">
          <GeckoIcon
            icon={componentFields.icon?.replace('far ', '') || 'fa-question'}
          />
          {componentFields.label}
        </div>
        <div className="builderComponent-header-actions">
          <div className="moveComponent">
            {showUpArrow && (
              <button
                className="moveComponent-arrow"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMoveComponent(componentIndex, componentIndex - 1);
                }}
              >
                <GeckoIcon icon="fa-arrow-alt-up" />
              </button>
            )}
            {showDownArrow && (
              <button
                className="moveComponent-arrow"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMoveComponent(componentIndex, componentIndex + 1);
                }}
              >
                <GeckoIcon icon="fa-arrow-alt-down" />
              </button>
            )}
          </div>
          <div className="delete">
            <button
              className="gecko-ui-btn btn btn-danger"
              onClick={onDeleteClick}
            >
              <span className="gecko-ui-icon far fa-trash-alt"></span>
            </button>
          </div>
          <div>
            <GeckoIcon icon={active ? 'angle-up' : 'angle-down'} />
          </div>
        </div>
      </div>
      {active && (
        <>
          <GeckoFields
            fields={getFields(type)}
            values={componentFields}
            handler={setBuilderFieldValue}
          />
        </>
      )}
    </div>
  );
};

export default BuilderComponent;
