import { useState, useEffect, useContext, useRef } from 'react';
import {
  GECKO_CONSTS,
  GeckoFields,
  GeckoIcon,
  GeckoToastService,
  GeckoLoadingService,
  GeckoModalService,
} from '@geckolabs/gecko-react-ui';
import { navigate } from '@reach/router';
import { AppContext, BuilderContext, TourContext } from '../../context';
import { useBuilder } from '../../hooks';
import { StepCard, ButtonRadio, ColorPicker, PageMask } from '../../components';
import LivePreview from '../../LivePreview';
import {
  AddBuilderComponent,
  BuilderComponent,
  TourCard,
  TourModal,
} from './components';
import GeckoRequest from '../../services/gecko-request';
import './index.scss';

export const defaultComponentFields = {
  url: {
    type: 'url',
    label: '',
    icon: 'far fa-globe',
    url: '',
    openInModal: false,
  },
  form: {
    type: 'form',
    label: '',
    icon: 'far fa-list',
    url: '',
    openInModal: true,
  },
  event: {
    type: 'event',
    label: '',
    icon: 'far fa-calendar-alt',
    url: '',
    openInModal: true,
  },
  chat: {
    type: 'chat',
    label: '',
    icon: 'far fa-comments',
    chatId: '',
  },
  whatsapp: {
    type: 'whatsapp',
    label: '',
    icon: 'fab fa-whatsapp',
    url: '',
  },
  thirdPartyWidget: {
    type: 'thirdPartyWidget',
    label: '',
    icon: 'far fa-comment-alt',
    vendor: '',
    embedCode: '',
  },
  embeddedVideo: {
    type: 'embeddedVideo',
    label: '',
    icon: 'far fa-video',
    embedCode: '',
  },
};

const DescriptionField = ({ description, placeholder, ...rest }) => (
  <>
    <span className="Description">{description}</span>
    <input placeholder={placeholder} {...rest} />
  </>
);

const Builder = ({ widgetId, location }) => {
  const [tourActive, setTourActive] = useState(location.state?.takeTour);
  const [tourStep, setTourStep] = useState(1);
  const [widget, setWidget] = useState(location.state?.widget || {});
  const [isLoadingWidget, setIsLoadingWidget] = useState(false);
  const { mobileSize } = useContext(AppContext);
  const tourAdvancedRef = useRef(null);

  const advanceTour = () => {
    if (!tourAdvancedRef.current) {
      tourAdvancedRef.current = true;
    }
    if (tourStep === 4) {
      setActiveStep(activeStep + 1);
    }
    setTourStep(tourStep + 1);
  };

  useEffect(() => {
    if (widgetId) {
      setIsLoadingWidget(true);
      GeckoRequest.get(`${process.env.REACT_APP_API_URL}/widgets/${widgetId}`)
        .then((response) => {
          setWidget(response.configuration);
        })
        .catch((error) => new GeckoToastService().error(error))
        .finally(() => setIsLoadingWidget(false));
    }
  }, [widgetId]);

  useEffect(() => {
    if (isLoadingWidget === true) {
      new GeckoLoadingService().show();
    } else {
      new GeckoLoadingService().hide();
    }
  }, [isLoadingWidget]);

  useEffect(() => {
    if (tourActive && tourStep === 1) {
      new GeckoModalService().open(
        () => <TourModal advanceTour={advanceTour} />,
        {
          onExit: () => {
            if (!tourAdvancedRef.current) setTourActive(false);
          },
          backdrop: 'static',
        }
      );
    }
    // eslint-disable-next-line
  }, [tourActive, tourStep]);

  const [activeComponent, setActiveComponent] = useState(0);
  const {
    activeStep,
    setActiveStep,
    fieldValues: values,
    setFieldValue,
    handleSubmit,
    requiredFields,
    errors,
    widgetSaving,
  } = useBuilder(defaultComponentFields.form, widget, widgetId);

  const getFooterButtonHandler = (step) => () => {
    if (step === 1 || step === 2) {
      return setActiveStep(step + 1);
    }

    return handleSubmit()();
  };

  const getStepDetails = (step, stepComplete) => {
    const lastStep = step === 3;
    const firstStep = step === 1;
    const footerButtons = [
      {
        type: lastStep && 'submit',
        buttonClass:
          firstStep || (lastStep && widgetId) ? 'secondary' : 'primary',
        name: lastStep
          ? widgetId
            ? 'Save and view Embed Code'
            : 'Save and get Embed Code'
          : 'Next Step',
        iconLeft: lastStep && 'code',
        iconRight: !lastStep && 'chevron-right',
        onClick: getFooterButtonHandler(step),
        disabled: lastStep && (!stepComplete || widgetSaving),
      },
    ];

    if (lastStep && widgetId) {
      footerButtons.splice(0, 0, {
        type: 'submit',
        buttonClass: 'primary',
        name: 'Save Changes',
        iconLeft: 'save',
        onClick: () => handleSubmit()(true),
        disabled: widgetSaving || (lastStep && !stepComplete),
      });
    }

    return {
      step,
      stepState: stepComplete ? 'complete' : 'incomplete',
      open: activeStep === step,
      onClickHeader: () => setActiveStep(step),
      footerButtons,
    };
  };

  const widgetFields = [
    {
      key: 'widgetName',
      label: 'Widget Name',
      required: requiredFields.includes('widgetName'),
      type: GECKO_CONSTS.FIELD_TYPE_TEXT,
      width: '100%',
      placeholder: 'Your widget name',
    },
    {
      key: 'widgetColor',
      label: 'Widget Colour',
      required: requiredFields.includes('widgetColor'),
      type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
      component: <ColorPicker />,
      placeholder: 'Choose your colour',
    },
    {
      key: 'widgetOpenIcon',
      label: 'Widget Icon',
      width: '100%',
      type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
      required: true,
      component: (
        <ButtonRadio
          id="widget-icon"
          value={values.widgetIcon}
          options={[
            { value: 'fa-bars', icon: 'far fa-bars' },
            { value: 'fa-question', icon: 'far fa-question' },
            { value: 'fa-calendar-alt', icon: 'far fa-calendar-alt' },
            { value: 'fa-ellipsis-h', icon: 'far fa-ellipsis-h' },
            { value: 'fa-comments', icon: 'far fa-comments' },
            {
              value: 'fa-caret-square-right',
              icon: 'far fa-caret-square-right',
            },
          ]}
        />
      ),
    },
    {
      key: 'openMessage',
      label: 'Open Message',
      type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
      width: '100%',
      component: (
        <DescriptionField
          placeholder="Chat with us or sign up for our event..."
          description="Direct users to open your widget using this optional message"
        />
      ),
    },
  ];

  const formComplete = !errors.step1Incomplete && !errors.step2Incomplete;

  return (
    <TourContext.Provider
      value={{
        endTour: () => setTourActive(false),
        tourActive,
        tourStep,
        advanceTour,
      }}
    >
      <div className="GeckoBuilder-Builder content-margin">
        <StepCard
          name={'Build Your Widget'}
          {...getStepDetails(1, !errors.step1Incomplete)}
        >
          {values.widgetComponents.map((component, index) => (
            <BuilderContext.Provider
              key={index}
              value={{
                components: values.widgetComponents,
                componentFields: component,
                componentErrors: errors.widgetComponents
                  ? errors.widgetComponents[index]
                  : null,
                setFieldValue,
                componentIndex: index,
                active: activeComponent === index,
                activeComponent,
                setActiveComponent,
                onDelete: () => {
                  setFieldValue('widgetComponents', [
                    ...values.widgetComponents.slice(0, index),
                    ...values.widgetComponents.slice(
                      index + 1,
                      values.widgetComponents.length
                    ),
                  ]);
                },
              }}
            >
              <BuilderComponent />
            </BuilderContext.Provider>
          ))}
          <AddBuilderComponent
            components={values.widgetComponents}
            setFieldValue={setFieldValue}
            setActiveComponent={setActiveComponent}
          />
        </StepCard>
        <StepCard
          name={'Widget Details'}
          {...getStepDetails(2, !errors.step2Incomplete)}
        >
          <div className="WidgetDetailsContent">
            <GeckoFields
              fields={widgetFields}
              values={values}
              handler={setFieldValue}
            />
            {tourActive && tourStep === 5 && (
              <TourCard
                className="BrandWidgetTour"
                title="Brand your Widget"
                content="Make sure your branding game is on point and befitting of your institution."
                btn={{
                  name: 'Next',
                  buttonClass: 'primary',
                  onClick: () => {
                    const widgetButton = document.body.querySelector(
                      '.GeckoEngagementWidget .WidgetButton'
                    );
                    if (widgetButton) {
                      widgetButton.click();
                    }
                    setTourStep(tourStep + 1);
                  },
                }}
                pointerPosition={mobileSize ? 'bottom' : 'left'}
              />
            )}
          </div>
        </StepCard>
        <StepCard name={'Review'} {...getStepDetails(3, formComplete)}>
          <div className="review">
            <GeckoIcon
              icon={
                formComplete ? 'fa-thumbs-up fa-2x' : 'fa-thumbs-down fa-2x'
              }
            />
            <span className="completionMessage">
              {formComplete
                ? 'Now for the last step!'
                : 'It looks like your widget has some issues.'}
            </span>
            <span className="actionMessage">
              {formComplete
                ? 'Save your widget and generate your embed code.'
                : 'Please review the errors below before proceeding.'}
            </span>
            {!formComplete && errors.step1Incomplete && (
              <span className="warningMessage">
                <GeckoIcon icon="exclamation-triangle" />{' '}
                {errors.step1Incomplete}
              </span>
            )}
            {!formComplete && errors.step2Incomplete && (
              <span className="warningMessage">
                <GeckoIcon icon="exclamation-triangle" />{' '}
                {errors.step2Incomplete}
              </span>
            )}
          </div>
        </StepCard>
        <LivePreview config={{ ...values, widgetCloseIcon: 'fa-times' }} />

        {tourActive && tourStep === 6 && (
          <TourCard
            className="LivePreviewTour"
            title="Live Preview"
            content="Preview your work at any time to see how your widget will look and feel when live."
            btn={{
              name: 'Got It',
              buttonClass: 'primary',
              onClick: () => {
                navigate('/new', { state: { takeTour: false }, replace: true });
                setTourStep(1);
                setTourActive(false);
                setActiveStep(1);
              },
            }}
            pointerPosition="right"
          />
        )}
        {tourActive && <PageMask />}
      </div>
    </TourContext.Provider>
  );
};

export default Builder;
