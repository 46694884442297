import { useContext } from 'react';
import { TourContext } from '../../../../context';
import { Button } from '../../../../components';
import { TourCard } from '..';
import { defaultComponentFields } from '../..';

const AddBuilderComponent = ({
  components,
  setFieldValue,
  setActiveComponent,
}) => {
  const { tourActive, tourStep, advanceTour } = useContext(TourContext);
  const onAddNewComponent = () => {
    const updatedComponents = [...components, defaultComponentFields.form];
    setFieldValue('widgetComponents', updatedComponents);
    setActiveComponent(updatedComponents.length - 1);
  };

  return (
    <div className="AddBuilderComponentContainer">
      <Button
        iconLeft="plus"
        name="Add Another Component"
        onClick={onAddNewComponent}
        buttonClass="primary btn-add"
        disabled={components.length > 10}
      />
      {tourActive && tourStep === 4 && (
        <TourCard
          className="AddAnotherComponentTour"
          title="Add more Components"
          content="You’re ready to grow your widget! Go ahead and add a new Component."
          btn={{
            name: 'Next',
            buttonClass: 'primary',
            onClick: advanceTour,
          }}
          pointerPosition="top"
        />
      )}
    </div>
  );
};

export default AddBuilderComponent;
