import { GeckoModal, GeckoModalService } from '@geckolabs/gecko-react-ui';
import { useEffect } from 'react';
import { Button } from '../../../../components';
import './index.scss';

const TourModal = ({ advanceTour }) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const modal = document.body.querySelector('.gecko-ui-modal-wrapper ')?.parentElement;
      if (modal) {
        modal.style.zIndex = 2147483647;
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, []);
  
  return (
    <GeckoModal name="Take the Tour">
      <div className="TourModal">
        <div className="tourVideo">
          <iframe
            title="Introduction to Widget Building"
            src="https://www.loom.com/embed/817545e5b4fc4411923a2d16392b4f77"
            frameborder="0"
          />
        </div>
        <p>
          Engaging with students has never been easier. Let us show you how.
        </p>
        <Button
          name="Get Started"
          iconRight="fa-arrow-right"
          buttonClass="primary"
          onClick={() => {
            advanceTour();
            new GeckoModalService().close();
          }}
        />
      </div>
    </GeckoModal>
  );
};

export default TourModal;
