import { Link } from '@reach/router';
import { GeckoIcon } from '@geckolabs/gecko-react-ui';

const navItems = [
  { name: 'Widgets', icon: 'shapes', href: '/widgets' },
  { name: 'Create New', icon: 'plus', href: '/new' },
];

const getLinkProps = ({ isCurrent }) => {
  if(isCurrent === true) {
    return {'aria-current': 'page'}
  }
  return {}
}

const NavItem = ({ name, icon, href }) => {
  return (
    <li>
      <Link to={href} getProps={getLinkProps}>
        <GeckoIcon icon={icon} />
        {name}
      </Link>
    </li>
  );
};

const HeaderNav = () => {
  return (
    <nav className="GeckoBuilder-Header-Nav">
      <ul>
        {navItems.map((navItem) => (
          <NavItem {...navItem} key={navItem.name}/>
        ))}
      </ul>
    </nav>
  );
};

export default HeaderNav;
