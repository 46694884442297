import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import Auth from '../../services/auth';

const useAuth = () => {
  const [loggedInUser, setLoggedInUser] = useState(Auth.getLoggedInUser());
  const [token, setToken] = useState(null);
  
  useEffect(() => {
      Auth.on('user logged in', setLoggedInUser);
      Auth.on('new token', setToken);
      return () => {
          Auth.removeListener('user logged in', setLoggedInUser);
          Auth.removeListener('new token', setToken);
      }
  }, [setLoggedInUser, setToken]);

  useEffect(() => {
      if(loggedInUser === null) {
          navigate('/');
      }
  }, [loggedInUser]);

  return {
      loggedInUser,
      authHeaders: {
          Authorization: `Bearer ${token}`
      }
  }
}

export default useAuth;