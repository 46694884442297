import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import {
  GeckoModalService,
  GeckoToastService,
} from '@geckolabs/gecko-react-ui';
import { useForm } from '..';
import GeckoRequest from '../../services/gecko-request';
import EmbedModal from '../../components/EmbedModal';

const checkStepComplete = (fields, errors) => {
  return !fields.some((field) => Object.keys(errors).includes(field));
};

const saveWidget = async (formValues, widgetId, saveOnly) => {
  const isUpdatingWidget = Boolean(widgetId) === true;

  const url = isUpdatingWidget
    ? `${process.env.REACT_APP_API_URL}/widgets/${widgetId}`
    : `${process.env.REACT_APP_API_URL}/widgets`;
  const widgetConfig = {
    ...formValues,
    widgetCloseIcon: 'fa-times',
  };
  try {
    const response = await GeckoRequest.post(url, {
      configuration: widgetConfig,
    });
    if (isUpdatingWidget) {
      new GeckoToastService().success(`${widgetConfig.widgetName} updated`);
      if (!saveOnly) {
        await new GeckoModalService().open(() => {
          return <EmbedModal widgetId={widgetId} existingWidget />;
        });
      }
      navigate('/widgets');
    } else {
      new GeckoToastService().success(`${widgetConfig.widgetName} created`);
      await new GeckoModalService().open(() => {
        return <EmbedModal widgetId={response.uuid} />;
      });
      navigate('/widgets');
    }
  } catch (error) {
    new GeckoToastService().error(error);
  }
};

const isShareLinkComponent = (type) => ['form', 'event'].includes(type);

const isValidShareLink = (url) =>
  url.startsWith('https://app.geckoform.com/') ||
  url.startsWith('https://gck.fm/');

const isValidChatId = (chatId) => chatId.match(/\w{15}/);

const useBuilder = (defaultComponentFields, widgetValues, widgetId) => {
  const [activeStep, setActiveStep] = useState(1);
  const [widgetSaving, setWidgetSaving] = useState(false);
  const {
    values,
    setFieldValue,
    setMultipleFieldValues,
    handleSubmit,
    requiredFields,
    errors,
  } = useForm({
    initialValues: {
      widgetName: '',
      widgetColor: '#000000',
      widgetOpenIcon: 'fa-bars',
      widgetComponents: [defaultComponentFields],
      openMessage: '',
    },
    onSubmit: (values) => async (saveOnly) => {
      setWidgetSaving(true);
      await saveWidget(values, widgetId, saveOnly);
      setWidgetSaving(false);
    },
    validate: (values) => {
      const errors = {
        widgetComponents: {},
      };
      const { widgetName, widgetColor, widgetOpenIcon, widgetComponents } =
        values;
      if (!widgetName) errors.widgetName = 'Widget Name is required';
      if (!widgetColor) errors.widgetColor = 'Widget Color is required';
      if (!widgetOpenIcon) errors.widgetOpenIcon = 'Widget Icon is required';
      widgetComponents.forEach((component, index) => {
        const componentErrors = Object.entries(component).reduce(
          (errorObject, [key, value]) => {
            if (
              key === 'url' &&
              isShareLinkComponent(component.type) &&
              !isValidShareLink(component.url)
            ) {
              return { ...errorObject, [key]: 'Webform Link is not valid' };
            }
            if (key === 'chatId' && !isValidChatId(component.chatId)) {
              return { ...errorObject, [key]: 'Chat ID is not valid' };
            }
            if (typeof value !== 'boolean' && !value) {
              return { ...errorObject, [key]: `${key} is required` };
            }
            return errorObject;
          },
          {}
        );
        if (Object.keys(componentErrors).length) {
          errors.widgetComponents[index] = componentErrors;
        }
      });

      if (!Object.keys(errors.widgetComponents).length)
        delete errors.widgetComponents;

      if (!checkStepComplete(['widgetComponents'], errors)) {
        errors.step1Incomplete =
          'Step 1 does not have all required information for each widget component.';
      }
      if (
        !checkStepComplete(
          ['widgetName', 'widgetColor', 'widgetOpenIcon'],
          errors
        )
      ) {
        errors.step2Incomplete =
          'Step 2 does not have all required information.';
      }

      return errors;
    },
    requiredFields: [
      'widgetName',
      'widgetColor',
      'widgetOpenIcon',
      'widgetComponents',
    ],
  });

  useEffect(() => {
    if (widgetValues) {
      const values = Object.entries(widgetValues).map(([name, value]) => {
        return { name, value };
      });
      setMultipleFieldValues(values);
    }
    // eslint-disable-next-line
  }, [widgetValues]);

  return {
    activeStep,
    setActiveStep,
    fieldValues: values,
    setFieldValue,
    handleSubmit,
    requiredFields,
    errors,
    widgetSaving,
  };
};

export default useBuilder;
