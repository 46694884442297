import { useEffect } from "react"
import Auth from "../../services/auth"

const Logout = () => {
    useEffect(() => {
        Auth.logout();
    });
    return null;
}

export default Logout;