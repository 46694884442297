import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GeckoGlobal } from '@geckolabs/gecko-react-ui';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@geckolabs/gecko-react-ui/lib/css';
import './index.scss';


ReactDOM.render(
  <React.StrictMode>
    <GeckoGlobal />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
