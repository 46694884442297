import { useEffect, useState } from 'react';

const useForm = ({ initialValues, onSubmit, validate, requiredFields }) => {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [requiredErrors, setRequiredErrors] = useState([]);
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  const checkFormForErrors = (formValues = values) => {
    let hasErrors = false;
    if (validate) {
      const formErrors = validate(formValues);
      hasErrors = !!Object.keys(formErrors).length;
      setErrors(formErrors);
    }
    if (requiredFields) {
      const requiredErrors = requiredFields.filter((requiredField) => {
        const value = formValues[requiredField];
        if (Array.isArray(value)) {
          return !value.length;
        }
        return !value;
      });
      if (!hasErrors && requiredErrors.length) hasErrors = true;
      setRequiredErrors(requiredErrors);
    }
    return hasErrors;
  };

  useEffect(() => {
    checkFormForErrors(values);
    // eslint-disable-next-line
  }, [values]);

  const updateValues = (newValues) => {
    const updatedValues = { ...values, ...newValues };
    setTouched({
      ...touched,
      ...Object.keys(newValues).reduce((newTouched, name) => {
        return {
          ...newTouched,
          [name]: true,
        };
      }, {}),
    });
    setValues(updatedValues);
  };

  const handleChange = (e) => updateValues({ [e.target.name]: e.target.value });

  const setFieldValue = (name, value) => updateValues({ [name]: value });

  const resetForm = () => {
    setTouched({});
    setValues(initialValues);
    setRequiredErrors([]);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (checkFormForErrors()) {
      setHasTriedToSubmit(true);
      return;
    }
    if (onSubmit) {
      return onSubmit(values);
    }
  };

  const setMultipleFieldValues = (fields) => {
    const newValues = fields.reduce((newValues, { name, value }) => {
      return {
        ...newValues,
        [name]: value,
      };
    }, {});

    updateValues(newValues);
  };

  return {
    values,
    touched,
    handleChange,
    setFieldValue,
    setMultipleFieldValues,
    resetForm,
    handleSubmit,
    errors,
    requiredErrors,
    requiredFields,
    hasTriedToSubmit,
  };
};

export default useForm;
