import './index.scss';

const ColorPicker = (props) => {
  return (
    <div className='ColorPicker form-control'>
      <input type='color' {...props} />
      <input type='text' {...props}/>
    </div>
  );
};

export default ColorPicker;