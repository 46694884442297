import { useEffect } from 'react';
import { navigate } from '@reach/router';
import useAuth from '../../hooks/useAuth';
import { Header, Footer } from '../../components';
import './index.scss';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { loggedInUser } = useAuth();

  useEffect(() => {
    if (!loggedInUser) {
      navigate('/');
    }
  }, [loggedInUser]);

  if (!loggedInUser) {
    return null;
  }

  return (
    <div className="AuthContainer">
      <Header />
      <div className="ComponentContainer">
        <Component {...rest} />
      </div>
      <div className="FooterContainer">
        <Footer />
      </div>
    </div>
  );
};

export default AuthRoute;
