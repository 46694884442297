import { GeckoIcon, GECKO_CONSTS } from '@geckolabs/gecko-react-ui';
import './index.scss';

const Footer = () => {
  return (
    <div className="GeckoEngagementWidget-Footer">
      <span className="help">
        <a
          href="http://academy.geckoengage.com/en/articles/5782037-engagement-widget"
          target="_blank"
          rel="noreferrer"
        >
          Need Help?
        </a>
      </span>
      <span>
        Made with{' '}
        <GeckoIcon style={GECKO_CONSTS.ICON_STYLE_SOLID} icon="fa-heart" /> by{' '}
        <a
          className="GeckoLink"
          href="https://www.geckoengage.com/"
          target="_blank"
          rel="noreferrer"
        >
          Gecko
        </a>
      </span>
    </div>
  );
};

export default Footer;
