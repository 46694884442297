import './index.scss';

const NotFound = () => {
  return (
    <div className='GeckoBuilder-NotFound'>
      404 page not found ¯\_(ツ)_/¯
    </div>
  );
};

export default NotFound;