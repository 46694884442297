import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return year + '-' + month + '-' + day;
};

const getLast7Days = () => {
  const last7Days = [];
  for (let i = 0; i < 8; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);

    last7Days.push(formatDate(date));
  }

  return last7Days.reverse();
};

const emptyStats = {
  labels: ['https://your-widget-page.com'],
  xValues: getLast7Days(),
  yValues: [Array.from({ length: 8 }, () => 0)],
};

const WidgetLineGraph = ({ stats, empty }) => {
  const { labels, xValues, yValues } = stats;
  const data = xValues.map((xValue, xIndex) => {
    const dataEntry = { name: xValue };
    labels.forEach((label, labelIndex) => {
      dataEntry[label] = yValues[labelIndex][xIndex];
    });
    return dataEntry;
  });

  return (
    <ResponsiveContainer width={300} minHeight={40}>
      <LineChart data={data}>
        <YAxis hide />
        <XAxis dataKey="name" hide />
        <Tooltip
          position={{ x: 300, y: -50 }}
          labelStyle={{
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: '700',
            marginTop: '5px',
          }}
          itemStyle={{
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: '700',
            marginTop: '5px',
          }}
          wrapperStyle={{ zIndex: 100 }}
        />
        {labels.map((label) => (
          <Line
            type="monotone"
            dataKey={label}
            stroke={empty ? '#8899AA' : '#5d9ed4'}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

WidgetLineGraph.defaultProps = { stats: emptyStats };

export default WidgetLineGraph;
