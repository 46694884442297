import { useState, useEffect, useContext } from 'react';
import { GeckoIcon } from '@geckolabs/gecko-react-ui';
import { AppContext } from '../../context';
import { GeckoLogo } from '..';
import HeaderNav from './components/HeaderNav';
import LogoutNav from './components/LogoutNav';
import './index.scss';

const MobileNav = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const { mobileSize } = useContext(AppContext);

  useEffect(() => {
    const livePreviewWidget = document.body.querySelector(
      '.GeckoEngagementWidget'
    );
    if (!livePreviewWidget) return;
    if (mobileDrawerOpen && mobileSize) {
      livePreviewWidget.style.display = 'none';
      return;
    }
    if (livePreviewWidget.style.display) {
      livePreviewWidget.style.display = null;
    }
  }, [mobileDrawerOpen, mobileSize]);

  return (
    <>
      <button
        className="GeckoBuilder-Mobile-Nav-Open-Button"
        onClick={() => setMobileDrawerOpen(true)}
      >
        <GeckoIcon icon="fa-bars" />
      </button>
      {mobileDrawerOpen && <div className="GeckoBuilder-Mobile-Nav-Mask" />}
      {mobileDrawerOpen && (
        <div className="GeckoBuilder-Mobile-Nav-Drawer">
          <div className="Drawer-Header">
            <GeckoLogo />
            <button
              className="GeckoBuilder-Mobile-Nav-Close-Button"
              onClick={() => setMobileDrawerOpen(false)}
            >
              <GeckoIcon icon="fa-times" />
            </button>
          </div>
          <div className="GeckoBuilder-Mobile-Nav-Items">
            <HeaderNav />
            <LogoutNav />
          </div>
        </div>
      )}
    </>
  );
};

const Header = () => {
  return (
    <header className="GeckoBuilder-Header">
      <GeckoLogo />
      <HeaderNav />
      <LogoutNav />
      <MobileNav />
    </header>
  );
};

export default Header;
