import {
  GECKO_CONSTS,
  GeckoCardTable,
  GeckoLoadingService,
  GeckoModalService,
  GeckoIcon,
} from '@geckolabs/gecko-react-ui';
import { navigate } from '@reach/router';
import WidgetLineGraph from './WidgetLineGraph';
import { EmbedModal, Button } from '../../components';
import GeckoRequest, { useGetRequest } from '../../services/gecko-request';
import './index.scss';
import { useEffect } from 'react';
import { GeckoToastService } from '@geckolabs/gecko-react-ui/lib/services';
import emptyStateImage from '../../assets/empty-state.png';

const widgetTemplate = {
  widgetName: 'Widget Template',
  widgetColor: '#000000',
  widgetOpenIcon: 'fa-comments',
  widgetComponents: [
    {
      type: 'url',
      label: 'Gecko Website',
      icon: 'far fa-link',
      url: 'https://www.geckoengage.com',
      openInModal: true,
    },
    {
      type: 'form',
      label: 'Form',
      icon: 'far fa-clipboard-list',
      url: 'https://app.geckoform.com/public/#/modern/21FO00r8r5nh3d00c6q2y26iwl',
      openInModal: true,
    },
    {
      type: 'event',
      label: 'Event',
      icon: 'far fa-calendar-plus',
      url: 'https://app.geckoform.com/events-embed/#/events-embed?uuid=21FO00r8r5nsj400gspt2ogzjp&cat_id=54&date_format=m%2Fd%2FY&time_format=g:i%20A',
      openInModal: true,
    },
    {
      type: 'chat',
      label: 'Gecko Chat',
      icon: 'far fa-comment-smile',
      chatId: 'yqWUYt3kqfUpIWK',
    },
    // {
    //   type: 'thirdPartyWidget',
    //   label: 'Gecko Intercom',
    //   icon: 'far fa-comment-alt-lines',
    //   vendor: 'intercom',
    //   embedCode: ''
    // }
  ],
};

const Widgets = () => {
  const [widgets, isLoadingWidgets, reloadWidgets] = useGetRequest(
    `${process.env.REACT_APP_API_URL}/widgets`,
    []
  );

  useEffect(() => {
    if (isLoadingWidgets === true) {
      new GeckoLoadingService().show();
    } else {
      new GeckoLoadingService().hide();
    }
  }, [isLoadingWidgets]);

  const setWidgetEnableStatus = (widget, enabled) => {
    return GeckoRequest.post(
      `${process.env.REACT_APP_API_URL}/widgets/${widget.id}`,
      { enabled }
    )
      .then(() => {
        new GeckoToastService().success(
          `${widget.name} ${enabled ? 'enabled' : 'disabled'}`
        );
        return reloadWidgets();
      })
      .catch(new GeckoToastService().error);
  };

  const cloneWidget = (id) => {
    const widgetToClone = widgets.find((w) => w.uuid === id);
    const clonedWidgetConfig = {
      ...widgetToClone.configuration,
      widgetName: `${widgetToClone.configuration.widgetName} (copy)`,
    };

    return GeckoRequest.post(`${process.env.REACT_APP_API_URL}/widgets`, {
      enabled: false,
      configuration: clonedWidgetConfig,
    })
      .then(() => {
        new GeckoToastService().success(
          `${widgetToClone.configuration.widgetName} cloned`
        );
        return reloadWidgets();
      })
      .catch(new GeckoToastService().error);
  };

  const cols = [
    {
      name: 'Widget Name',
      key: 'name',
      type: GECKO_CONSTS.TABLE_CELL_TYPE_CUSTOM,
      render: (_, widget) => (
        <div onClick={() => navigate(`/${widget.id}`)}>{widget.name}</div>
      ),
    },
    {
      name: 'Times Opened (Last 7 Days)',
      key: 'openClickStats',
      type: GECKO_CONSTS.TABLE_CELL_TYPE_DEFAULT,
      render: (stats) => {
        const hasData = !!stats.yValues?.length;
        const aggregate =
          stats.yValues?.reduce(
            (total, yValueArray) =>
              total + yValueArray.reduce((total, yValue) => total + yValue, 0),
            0
          ) || 0;
        return (
          <>
            <span>{aggregate}</span>
            <WidgetLineGraph
              stats={hasData ? stats : undefined}
              empty={!hasData}
            />
          </>
        );
      },
    },
    {
      name: 'Components Clicked (Last 7 Days)',
      key: 'componentClickStats',
      type: GECKO_CONSTS.TABLE_CELL_TYPE_DEFAULT,
      render: (stats) => {
        const hasData = !!stats.yValues?.length;
        const aggregate =
          stats.yValues?.reduce(
            (total, yValueArray) =>
              total + yValueArray.reduce((total, yValue) => total + yValue, 0),
            0
          ) || 0;
        return (
          <>
            <span>{aggregate}</span>
            <WidgetLineGraph
              stats={hasData ? stats : undefined}
              empty={!hasData}
            />
          </>
        );
      },
    },
    {
      name: 'Status',
      key: 'active',
      type: GECKO_CONSTS.TABLE_CELL_TYPE_STATUS,
      styles: [
        {
          name: 'Active',
          type: GECKO_CONSTS.STATUS_TYPE_SUCCESS,
          key: 'true',
        },
        {
          name: 'Disabled',
          type: GECKO_CONSTS.STATUS_TYPE_DANGER,
          key: 'false',
        },
      ],
    },
    {
      type: GECKO_CONSTS.TABLE_CELL_TYPE_DROPDOWN,
      class: 'GeckoBuilder-edit-widget-dropdown-cell',
      items: [
        {
          name: (
            <>
              <GeckoIcon icon="fa-edit" />
              Edit Widget
            </>
          ),
          handler: (widget) => {
            navigate(`/${widget.id}`);
          },
        },
        {
          name: (
            <>
              <GeckoIcon icon="fa-code" />
              View Embed Code
            </>
          ),
          handler: (widget) => {
            new GeckoModalService().open(() => {
              return <EmbedModal widgetId={widget.id} existingWidget />;
            });
          },
        },
        {
          name: (
            <>
              <GeckoIcon icon="fa-clone" />
              Clone Widget
            </>
          ),
          handler: (widget) => cloneWidget(widget.id),
        },
        {
          name: (
            <>
              <GeckoIcon icon="fa-pause" />
              Disable Widget
            </>
          ),
          handler: (widget) => setWidgetEnableStatus(widget, false),
          hideWhen: ({ active }) => active === 'false',
        },
        {
          name: (
            <>
              <GeckoIcon icon="fa-pause" />
              Enable Widget
            </>
          ),
          handler: (widget) => setWidgetEnableStatus(widget, true),
          hideWhen: ({ active }) => active === 'true',
        },

        {
          name: (
            <>
              <GeckoIcon icon="fa-trash-alt" />
              Delete Widget
            </>
          ),
          type: GECKO_CONSTS.BTN_TYPE_DANGER,
          handler: (widget) => {
            return GeckoRequest.delete(
              `${process.env.REACT_APP_API_URL}/widgets/${widget.id}`
            )
              .then(() => {
                new GeckoToastService().success(`${widget.name} deleted`);
                return reloadWidgets();
              })
              .catch(new GeckoToastService().error);
          },
        },
      ],
    },
  ];
  const rows = widgets.map((widget) => {
    return {
      id: widget.uuid,
      name: widget.configuration.widgetName,
      openClickStats: widget.openClickStats,
      componentClickStats: widget.componentClickStats,
      active: String(widget.enabled),
    };
  });

  const hasWidgets = !!widgets?.length;

  return (
    <div className="Widgets">
      {hasWidgets && (
        <GeckoCardTable
          name="Widgets"
          cols={cols}
          rows={rows}
          pagination={null}
        />
      )}
      {!hasWidgets && (
        <div className="noWidgets">
          <div>
            <div className="noWidgets-image-container">
              <img src={emptyStateImage} alt="No Widgets" />
            </div>
            <h1>No widgets created</h1>
            <p>
              Create your first widget and start increasing student engagement!
            </p>
            <div className="noWidgets-options-container">
              <Button
                name="View Example Widget"
                iconLeft="fa-eye"
                buttonClass="secondary"
                onClick={() =>
                  navigate('/new', { state: { widget: widgetTemplate } })
                }
              />
              <Button
                name="Create Your First Widget"
                iconLeft="fa-plus"
                buttonClass="primary"
                onClick={() => navigate('/new', { state: { takeTour: true } })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Widgets;
