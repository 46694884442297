import { useEffect } from 'react';
import './index.scss';

const PageMask = () => {
  useEffect(() => {
    const maskDiv = document.createElement('div');
    if (!document.body.querySelector('.PageMask')) {
      maskDiv.classList = ['PageMask'];
      document.body.appendChild(maskDiv);
    }

    return () => {
      document.body.removeChild(maskDiv);
    }
  })
  return null;
};

export default PageMask;
