import { useCallback, useEffect, useState } from 'react';
import { GeckoToastService } from '@geckolabs/gecko-react-ui';
import Auth from '../auth';

const logoutUser = () => {
  Auth.logout();
  new GeckoToastService().error('Your session has timed out');
};

export const refresh = async (cb = () => {}) => {
  const token = Auth.getToken();
  if (!token) {
    return cb();
  }
  if (Auth.validateToken(token)) {
    return cb();
  }
  const response = await fetch(
    `${process.env.REACT_APP_AUTHENTICATION_URL}/refresh`,
    {
      credentials: 'include',
    }
  );
  if (response.status === 401) {
    return logoutUser();
  }
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  const { accessToken } = await response.json();
  if (!Auth.validateToken(accessToken)) {
    return logoutUser();
  }
  Auth.setToken(accessToken);
  return cb();
};

const GeckoRequest = {
  get: (url, customHeaders = {}, extraOptions = {}) =>
    refresh(async () => {
      const response = await fetch(url, {
        method: 'GET',
        ...extraOptions,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
          ...customHeaders,
        },
      });
      if (response.status === 401) return logoutUser();
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      if (response.status !== 204) {
        return response.json();
      }
      return null;
    }),
  post: (url, body, customHeaders = {}) =>
    refresh(async () => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
          ...customHeaders,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 401) return logoutUser();
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      if (response.status !== 204) {
        return response.json();
      }
      return null;
    }),
  delete: (url, customHeaders = {}) =>
    refresh(async () => {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
          ...customHeaders,
        },
      });
      if (response.status === 401) return logoutUser();
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      if (response.status !== 204) {
        return response.json();
      }
      return null;
    }),
};

export const useGetRequest = (url, initialValue = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(initialValue);

  const makeRequest = useCallback(() => {
    setIsLoading(true);
    return GeckoRequest.get(url)
      .then((response) => {
        setResponse(response);
        return response;
      })
      .catch((error) => new GeckoToastService().error(error))
      .finally(() => setIsLoading(false));
  }, [setIsLoading, setResponse, url]);

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  return [response, isLoading, makeRequest];
};

export default GeckoRequest;
