import { GeckoIcon } from '@geckolabs/gecko-react-ui';
import './index.scss'

const Button = ({ type, buttonClass, name, iconLeft, iconRight, disabled, onClick }) => {
  const btnClassName = `btn btn-${buttonClass || 'default'}`;

  return (
    <button
      type={type || 'button'}
      className={`GeckoBuilder-Button ${btnClassName}`}
      disabled={disabled}
      onClick={onClick}
      aria-label={name}
    >
      {iconLeft && <GeckoIcon icon={iconLeft} />}
      <span>{name}</span>
      {iconRight && <GeckoIcon icon={iconRight} />}
    </button>
  );
};

export default Button;
