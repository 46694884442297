import { useState, useEffect } from 'react';

const useApp = () => {
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth !== windowWidth) {
        return setWindowWidth(window.innerWidth);
      }
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  });

  return {
    loading,
    setLoading,
    mobileSize: windowWidth <= 720,
  };
};

export default useApp;
