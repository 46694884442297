import { Router } from '@reach/router';
import { AppContext } from './context';
import { useApp } from './hooks';
import {
  AuthRoute,
  Builder,
  Login,
  Logout,
  NotFound,
  Widgets,
  MagicLink,
} from './routes';

const App = () => {
  const appConfig = useApp();

  return (
    <div className="App">
      <AppContext.Provider value={appConfig}>
        <Router className="Router">
          <Login path="/" />
          <Logout path="/logout" />
          <MagicLink path="/magic-link/:token"/>
          <AuthRoute path="/new" component={Builder} />
          <AuthRoute path="/:widgetId" component={Builder} />
          <AuthRoute path="/widgets" component={Widgets} />
          <NotFound default />
        </Router>
      </AppContext.Provider>
    </div>
  );
};

export default App;
