import { useEffect, useContext } from 'react';
import { GeckoIcon } from '@geckolabs/gecko-react-ui';
import { TourContext } from '../../../../context'
import { Button } from '../../../../components';
import './index.scss';

const TourCard = ({ title, content, btn, pointerPosition = 'left', className }) => {
  const { endTour } = useContext(TourContext);

  useEffect(() => {
    document.body.querySelector(`.TourCard.${className}`).scrollIntoViewIfNeeded();
  })

  return (
    <div className={`TourCard ${className}`}>
      <div className='Header'>
        <span>{title}</span>
        <button onClick={endTour}>
          <GeckoIcon icon='fa-times' />
        </button>
      </div>
      <div className='Content'>
        <p>{content}</p>
        <Button {...btn} />
      </div>
      <div className={`Pointer ${pointerPosition}`} />
    </div>
  );
};

export default TourCard;