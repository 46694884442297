import {
  GeckoModal,
  GeckoModalService,
  GeckoToastService,
  GeckoBtn,
  GeckoField,
  GECKO_CONSTS,
  GeckoIcon,
} from '@geckolabs/gecko-react-ui';
import GeckoRequest from '../../services/gecko-request';
import { isValidEmail } from '../../services/helpers';
import { Button } from '../../components';
import { useForm } from '../../hooks';
import './index.scss';
import Tooltip from '../Tooltip';

const putSnippetOnClipboard = async (snippet) => {
  await window.navigator.clipboard.writeText(snippet);
  new GeckoToastService().success('Embed code copied to clipboard');
};

const sendSetupInstructions = async (to, widgetId) => {
  await GeckoRequest.post(
    `${process.env.REACT_APP_API_URL}/widgets/setup/${widgetId}`,
    { to }
  );
};

const SendSetupField = ({ error, existingWidget, ...rest }) => {
  return (
    <>
      <input {...rest} />
      {error && (
        <Tooltip message={error}>
          <div className="errorIcon">
            <GeckoIcon icon="fa-times-circle" />
          </div>
        </Tooltip>
      )}
      <Button
        type="submit"
        buttonClass="primary"
        iconLeft={existingWidget ? 'fa-paper-plane' : 'fa-save'}
        name={existingWidget ? 'Send' : 'Finish Set Up'}
      />
    </>
  );
};

const EmbedModal = ({ widgetId, existingWidget }) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    hasTriedToSubmit,
    resetForm,
  } = useForm({
    initialValues: { to: '' },
    onSubmit: async ({ to }) => {
      if (!to) {
        return new GeckoModalService().close();
      }
      try {
        await sendSetupInstructions(to, widgetId);
        resetForm();
        new GeckoToastService().success('Setup Email Sent');
      } catch (err) {
        return new GeckoToastService().error(err);
      }
      new GeckoModalService().close();
    },
    validate: ({ to }) => {
      const errors = {};
      if (to && !isValidEmail(to)) {
        errors.to = 'Not a valid email';
      }
      return errors;
    },
  });

  const snippet = `
    <script>
      window.GeckoEngagementWidget = {
        widgetId: "${widgetId}",
      };
    </script>
    <script src="${process.env.REACT_APP_EMBED_URL}"></script>
  `;
  return (
    <GeckoModal name="Your Embed Code">
      <div className="EmbedModal">
        <p>
          Copy the code snippet below and paste it into the body of your website
          on any page you want the widget to appear. For more information,{' '}
          <a
            href="http://academy.geckoengage.com/en/"
            target="_blank"
            rel="noreferrer"
          >
            visit our academy
          </a>
          .
        </p>
        <code>
          <pre>
            <div>
              <span>{'<'}</span>
              <span className="EmbedModal-tag">{'script'}</span>
              <span>{'>'}</span>
            </div>
            <div>
              <span className="EmbedModal-object">{'  window'}</span>
              {'.GeckoEngagementWidget = {'}
            </div>
            <span>{'    widgetId:'}</span>
            <span className="EmbedModal-string">{` "${widgetId}"`}</span>
            <div>{'  };'}</div>
            <div>
              <span>{'</'}</span>
              <span className="EmbedModal-tag">{'script'}</span>
              <span>{'>'}</span>
            </div>
            <span>{'<'}</span>
            <span className="EmbedModal-tag">{'script'}</span>
            <span className="EmbedModal-attribute">{' src'}</span>
            <span>{'='}</span>
            <span className="EmbedModal-string">
              {`"${process.env.REACT_APP_EMBED_URL}"`}
            </span>
            <span>{'>'}</span>
            <span>{'</'}</span>
            <span className="EmbedModal-tag">{'script'}</span>
            <span>{'>'}</span>
          </pre>
          <GeckoBtn
            name=""
            icon="fa-clipboard"
            handler={() => putSnippetOnClipboard(snippet)}
          />
        </code>
        <form onSubmit={handleSubmit}>
          <GeckoField
            field={{
              key: 'to',
              label: 'Send your widget setup instructions by email (optional)',
              placeholder: 'joe.bloggs@email.com',
              type: GECKO_CONSTS.FIELD_TYPE_CUSTOM,
              component: (
                <SendSetupField
                  error={hasTriedToSubmit && errors.to ? errors.to : undefined}
                  existingWidget={existingWidget}
                />
              ),
            }}
            values={values}
            handler={setFieldValue}
          />
        </form>
      </div>
    </GeckoModal>
  );
};

export default EmbedModal;
