import { useCallback, useEffect, useState } from 'react';
import { GeckoLogo } from '../../components';
import {
  GeckoToastService,
  GeckoLoadingService,
} from '@geckolabs/gecko-react-ui';
import { navigate, Link } from '@reach/router';
import './index.scss';
import { refresh } from '../../services/gecko-request';
import { isMobile } from '../../services/helpers';
import Auth from '../../services/auth';
import magicLinkImage from '../../assets/magic-link.png';
import { Button } from '../../components';

const LandingPage = ({ emailAddress }) => {
  return (
    <div className="Login-landing-page">
      <img src={magicLinkImage} alt="Magic Link Envelope" />
      <div>
        <h1>Check your inbox</h1>
        <p>
          We have sent an email to <strong>{emailAddress}</strong> with a login
          link.
        </p>
      </div>
    </div>
  );
};

const Login = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);

  const checkToken = async () => {
    setIsLoading(true);
    await refresh();
    setIsLoading(false);
    const loggedInUser = Auth.getLoggedInUser();
    if (loggedInUser) {
      navigate('/widgets');
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (isLoading) {
      new GeckoLoadingService().show();
    } else {
      new GeckoLoadingService().hide();
    }
  }, [isLoading]);

  const [emailAddress, setEmailAddress] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [hasSubmittedLogin, setHasSubmittedLogin] = useState(false);

  useEffect(() => {
    if (location.state?.loggingIn) {
      setTermsAccepted(true);
    }
    if (location.state?.loggingIn === false) {
      setTermsAccepted(false);
    }
  }, [location.state?.loggingIn]);

  const isFormInvalid = useCallback(
    () => !emailAddress || !termsAccepted,
    [emailAddress, termsAccepted]
  );

  const login = useCallback(async () => {
    try {
      await Auth.initiateAuthentication(emailAddress, termsAccepted);
      setHasSubmittedLogin(true);
    } catch (error) {
      new GeckoToastService().error(error);
    }
  }, [setHasSubmittedLogin, emailAddress, termsAccepted]);

  if (hasSubmittedLogin) {
    return <LandingPage emailAddress={emailAddress} />;
  }

  const isLoggingIn = location.state?.loggingIn;
  const blurb = isLoggingIn
    ? 'Welcome back. Log in to access your widgets.'
    : 'Create and embed your widget and start increasing student engagement!';
  const buttonText = isLoggingIn ? 'Log In' : 'Sign Up';
  const statusQuestion = isLoggingIn ? 'No account?' : 'Returning user?';
  const statusCta = isLoggingIn
    ? 'Sign up to create your widget.'
    : 'Login to access your widgets.';
  const emailInputLabel = isMobile()
    ? 'Email address'
    : `${
        isLoggingIn ? 'Log in' : 'Sign up'
      } with your institution email address`;
  return (
    <div className="Login">
      <GeckoLogo />
      <div className="Login-form">
        <form>
          <header>
            <h1>The Student Engagement Widget</h1>
            <p>{blurb}</p>
          </header>
          <div className="Login-email form-floating">
            <input
              class="form-control"
              name="email"
              type="email"
              placeholder="joe.bloggs@geckoengage.com"
              value={emailAddress}
              onChange={(event) => setEmailAddress(event.target.value)}
            />
            <label htmlFor="email">{emailInputLabel}</label>
          </div>
          {!isLoggingIn && (
            <div className="Login-terms">
              <input
                name="terms"
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <span>
                I've read and accept the{' '}
                <a
                  href="https://geckoengage.com/gecko-widget-terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>{' '}
                of use.
              </span>
            </div>
          )}
        </form>
        <Button
          name={buttonText}
          iconLeft="fa-shapes"
          buttonClass="primary"
          onClick={login}
          disabled={isFormInvalid()}
        />
        <span className="Login-switch-message">
          {statusQuestion}{' '}
          <Link to="" state={{ loggingIn: !isLoggingIn }}>
            {statusCta}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Login;
