import { useEffect } from 'react';
import { navigate } from '@reach/router';
import { GeckoLoadingService } from '@geckolabs/gecko-react-ui';
import Auth from '../../services/auth';

const MagicLink = ({ token }) => {
  const loader = new GeckoLoadingService();
  const checkForClaimCode = async () => {
    const claimCodeReceived = await Auth.claimCode(token);
    loader.hide();
    if (!claimCodeReceived) return navigate('/');
    navigate('/widgets');
  };

  useEffect(() => {
    if (!token) return navigate('/');
    loader.show();
    checkForClaimCode();
  });

  return (
    <div className="MagicLink-landing-page" />
  );
};

export default MagicLink;