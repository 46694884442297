import jwtDecode from 'jwt-decode';
import { GeckoToastService } from '@geckolabs/gecko-react-ui';
import { EventEmitter } from 'events';
import GeckoRequest from '../gecko-request';
import { navigate } from '@reach/router';

const emitter = new EventEmitter();

const getLoggedInUser = () =>
  JSON.parse(localStorage.getItem('logged-in-user'));
const setLoggedInUser = (user) => {
  if (!user) {
    localStorage.removeItem('logged-in-user');
  } else {
    localStorage.setItem('logged-in-user', JSON.stringify(user));
  }
  emitter.emit('user logged in', user);
};

const getToken = () => localStorage.getItem('token');

const setToken = (token) => {
  if (!token) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', token);
  }
  emitter.emit('new token', token);
};

const validateToken = (token) => {
  try {
    const { email, exp } = jwtDecode(token);
    return email && Date.now() < exp * 1000;
  } catch (err) {
    return false;
  }
};

const initiateAuthentication = async (email, acceptedTerms) => {
    const result = await fetch(`${process.env.REACT_APP_AUTHENTICATION_URL}/login`, {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json'
        },
        body: JSON.stringify({email, acceptedTerms})
    });
    if(result.ok !== true) {
        const error = await result.text();
        throw error;
    }
    return true;
}

const handleLoginError = () => {
    logout();
    new GeckoToastService().error('Error logging in');
    return false;
  };
  
const claimCode = async (code) => {
  try {
    const { accessToken } = await GeckoRequest.get(
      `${process.env.REACT_APP_AUTHENTICATION_URL}/claim`,
      { Authorization: `Bearer ${code}` },
      { credentials: 'include' }
    );
    const { email } = jwtDecode(accessToken);

    if (!email) {
      return handleLoginError();
    }

    setToken(accessToken);
    setLoggedInUser({ email });
    return true;
  } catch (error) {
      console.log(error);
    return handleLoginError();
  }
};

const logout = () => {
  setToken(null);
  setLoggedInUser(null);
  if (window.location.pathname !== '/') {
    navigate('/');
  }
};

const Auth = {
  on: emitter.on,
  removeListener: emitter.removeListener,
  getLoggedInUser,
  getToken,
  initiateAuthentication,
  claimCode,
  logout,
  validateToken,
  setToken,
};

export default Auth;
