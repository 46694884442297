import geckoLogo from '../../assets/gecko-logo.svg';
import { Link } from '@reach/router';
import './index.scss';

const GeckoLogo = () => (
  <Link to='/widgets'>
    <img className="GeckoBuilder-Logo" src={geckoLogo} alt="Gecko Logo" />
  </Link>
);

export default GeckoLogo;
